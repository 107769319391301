<template>
  <v-app>
    <div class="bg-white">
      <table width="80%">
        <tr>
          <td width="60%">
            <p>Date: 15th September 2022</p>
            <p>Company Name: Ace Singapore Pte Ltd / 199304372N</p>
            <br />
            <p>FULL NRIC NAME: Susie's Casuals</p>
            <p>NRIC No.:</p>
            <p>Block:</p>
            <p>Street: BLK 574 ANG MO KIO AVENUE 10 Singapore</p>
            <p>Unit: #01-1827</p>
            <p>Postal Code: 560574</p>
            <br />
            <p>Contact No. (Mobile): +65-1234-9876 (Mr. Jie Yao)</p>
          </td>
          <td width="40%"></td>
        </tr>
        <tr>
          <td colspan="2">
            <p>
              PROPOSED INTERIOR DESIGN &amp; RENOVATION AT BLK 574 ANG MO KIO
              AVENUE 10 #01-1827 Singapore 560574
            </p>
          </td>
        </tr>
      </table>
    </div>
  </v-app>
</template>

<script>
export default {
  mixins: [],
  data() {
    return {};
  },
  components: {},
  methods: {},
  beforeDestroy() {},
  beforeMount() {},
  mounted() {},
  computed: {},
};
</script>
